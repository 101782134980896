<template>
  <main class="portfolio" v-if="blog">
    <div class="blog-container">
      <div
        class="blog-img"
        :style="{ backgroundImage: `url(${returnImage(blog.blog_picture)})` }"
      ></div>
      <div class="blog-wrapper">
        <button class="btn-previous" v-if="currentBlogIndex !== 0">
          <i class="fas fa-chevron-left" @click="previousArticle"></i>
        </button>
        <div class="blog-detail-container">
          <h2 class="blog-detail-title">{{ blog.title }}</h2>
          <div class="blog-detail-summary">
            <span> <i class="fas fa-user"></i>By {{ blog.author }}</span>
            <span>
              <i class="fas fa-clock"></i>Posted
              {{ getTimestamp(blog.created_at) }}</span
            >
          </div>

          <span class="blog-detail-content" markdown="1">
            {{ blog.content }}
          </span>
        </div>
        <button
          class="btn-next"
          v-if="currentBlogIndex + 1 !== getBlogList.length"
        >
          <i class="fas fa-chevron-right" @click="nextArticle"></i>
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import API from "@/api/utils";

export default {
  needSerialize: true,
  async created() {
    this.$store.dispatch("fetchBlogDetail", this.$route.params.id);
  },
  computed: {
    blog() {
      return this.$store.state.blogDetail;
    },
    getBlogList() {
      return this.$store.getters.getBlogList;
    },
    currentBlogIndex() {
      return this.getBlogList.findIndex((item) => item.id === this.blog.id);
    },
  },
  methods: {
    previousArticle() {
      const id = this.getBlogList[this.currentBlogIndex - 1].id;
      window.location = `${id}`;
    },
    nextArticle() {
      const id = this.getBlogList[this.currentBlogIndex + 1].id;
      window.location = `${id}`;
    },
    returnImage(imgLoc) {
      const host = API.getBaseURL();
      const img = imgLoc ? imgLoc.image.url : "";
      return `${host}${img}`;
    },
    getTimestamp(date) {
      const resultDate = Date.now() - Date.parse(date);
      const differenceInHours = Math.ceil(Math.abs(resultDate) / 36e5);
      const differenceInDays = Math.ceil(resultDate / (1000 * 60 * 60 * 24));
      const differenceInMins = Math.floor(resultDate / 1000 / 60);

      if (differenceInMins < 60) {
        return `${differenceInMins} minute${
          differenceInMins <= 1 ? "" : "s"
        } ago `;
      } else if (differenceInHours > 24) {
        return `${differenceInDays} day${
          differenceInDays === 1 ? "" : "s"
        } ago `;
      } else if (differenceInDays > 31) {
        return new Date(date).toISOString().split("T")[0];
      } else {
        return `${differenceInHours} hour${
          differenceInHours === 1 ? "" : "s"
        } ago`;
      }
    },
  },
};
</script>

<style></style>
